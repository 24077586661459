import React, { Component } from 'react';

class Controls extends Component {
    constructor(props) {
        super(props)
        this.state = { showPicker: false }
        this.engine = props.engine;
    }

    renderExcludedEmoji() {
        return this.engine.getExcludedEmoji().map((item) => {
            return (<button className="Excluded" key={item} onClick={(e) => this.onRemove(e, item)}>{item}</button>)
        })
    }

    excluded() {
        if (this.engine.getExcludedEmoji().length <= 0) {
            return null;
        }

        return (
            <div>
                <h5 style={{'margin': '10px'}}>Current Excluded Emoji:</h5>
                <div className="ExcludeContainer">
                    {this.renderExcludedEmoji()}
                </div>
            </div>
        );
    }

    onPick(_e, emoji) {
        this.engine.addExcludedEmoji(emoji.emoji);
        this.forceUpdate();
    }

    onRemove(e, emoji) {
        this.engine.removeExcludedEmoji(emoji);
        this.forceUpdate();
        e.preventDefault();
    }

    togglePicker(e) {
        this.setState({
            showPicker: !this.state.showPicker
        });
        e.preventDefault();
    }

    clickedRoll(e) {
        e.preventDefault();
        window.dispatchEvent(new Event('onDoRoll'));
    }

    render() {
        return (
            <div className="ControlBox">
                <a className="button" href="/roll" onClick={(e) => this.clickedRoll(e)}>🎲 ROLL EM'! 🎲</a>
                <br />
                <p>Made by <a href="https://twitter.com/doawoo">@doawoo</a></p>
            </div>
        )
    }
}

export default Controls;