const emojis = require('emojis-list')

class EmojiEngine {
    constructor() {
        this.exclude = []
    }

    addExcludedEmoji(emoji) {
        if (this.exclude.indexOf(emoji) === -1) {
            this.exclude.push(emoji);
        }
    }

    removeExcludedEmoji(emoji) {
        const toRemove = this.exclude.indexOf(emoji)
        if (toRemove > -1) {
            this.exclude.splice(toRemove, 1);
        }
    }

    getExcludedEmoji() {
        return this.exclude;
    }

    getRandomEmoji() {
        let pick = emojis[Math.floor(Math.random() * emojis.length)];

        while (this.exclude.indexOf(pick) !== -1) {
            console.log('FAULT');
            pick = emojis[Math.floor(Math.random() * emojis.length)];
        }

        return pick;
    }
}

export default EmojiEngine;