import React, { Component } from 'react';
import Twemoji from 'react-twemoji';

class RollerBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rolling: false,
            locked: false,
            interval: null,
            current: props.initial || props.engine.getRandomEmoji()
        }

        window.addEventListener('onDoRoll', () => this.roll());
    }

    toggleLock(e) {
        this.setState({
            locked: !this.state.locked
        });
        e.preventDefault();
    }

    roll() {
        if (this.state.locked) {
            return;
        }

        const inter = setInterval(() => {
            this.setState({
                rolling: true,
                current: this.props.engine.getRandomEmoji()
            })
        }, 100);

        setTimeout(() => {
            this.setState({ rolling: false });
            clearInterval(inter);
        }, Math.floor(Math.random() * 3000));
    }

    getId() {
        return this.props.key;
    }

    render() {
        let classType = "RollerBox";

        if (this.state.locked) {
            classType += " Locked"
        }

        return (
            <a href="/lock" id={this.props.boxId} className={classType} onClick={(e) => this.toggleLock(e)}>
                <p className="EmojiDisplay">
                    <Twemoji options={{ className: 'twemoji' }}>{this.state.current}</Twemoji>
                </p>
                <button className="DeleteButton" onClick={this.props.onDeletePressed}>x</button>
            </a>
        );
    }
}

export default RollerBox;