import React, { Component } from 'react';
import RollerBox from './components/RollerBox';
import EmojiEngine from './components/EmojiEngine';
import Controls from './components/Controls';

const uuidv1 = require('uuid/v1');

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      engine: new EmojiEngine(),
      boxes: []
    }
  }

  addBox(e) {
    const key = uuidv1();
    this.setState({
      boxes: [...this.state.boxes, this.createBox()]
    });
    e.preventDefault();
  }

  createBox(item = null) {
    const key = uuidv1();
    return <RollerBox engine={this.state.engine} key={key} boxId={key} initial={item} onDeletePressed={(e) => this.deleteBox(e)} />
  }

  deleteBox(e) {
    this.setState({
      boxes: this.state.boxes.filter(item => item.key !== e.target.parentElement.id)
    });
  }

  onRollClick(e) {
    this.state.boxes.forEach(box => {
      box.roll()
    });
    e.preventDefault();
  }

  saveRoll(e) {
    e.preventDefault()

    if (this.state.boxes.length < 1) {
      return;
    }

    let exist = JSON.parse(window.localStorage.getItem('saved'));
    let add = [...document.getElementsByClassName('twemoji')].map(item => item.alt);
    if (add.length < 1) {
      return;
    }

    exist.push(add);

    window.localStorage.setItem('saved', JSON.stringify(exist));
    this.forceUpdate();

    const select = document.getElementsByClassName("SavedList")[0];
    if (select) {
      select.selectedIndex = null;
    }
  }

  getSaved() {
    if (!window.localStorage.getItem('saved')) {
      this.clearSaved();
    }
    
    let exist = JSON.parse(window.localStorage.getItem('saved'));
    return exist.reverse().map((item, index) => <option key={index}>{item.join(' ')}</option>)
  }

  loadSaved(e) {
    e.preventDefault()
    if (!window.localStorage.getItem('saved')) {
      this.clearSaved();
    }
    
    const exist = JSON.parse(window.localStorage.getItem('saved'));
    const index = document.getElementsByClassName("SavedList")[0].selectedIndex;
    const restore = exist.reverse()[index];

    const newBoxes = restore.map(item => this.createBox(item));
    this.setState({
      boxes: newBoxes
    });
  }

  clearSaved(e) {
    if (e) {
      e.preventDefault()
    }
    window.localStorage.setItem('saved', JSON.stringify([]));
    this.forceUpdate();
  }
  
  render() {
    return (
      <div className="App">
        <h1 className="Title">Emoji Roller</h1>
        <div className="EmojiRollerContainer">
          {this.state.boxes}
          <a href="/add" className="RollerBox" onClick={e => this.addBox(e)}>+</a>
          <br />
        </div>
        <small>🔒 Click a box to lock! 🔒</small>
        <Controls engine={this.state.engine} />
        <a href='https://ko-fi.com/F2F81E5E8' target='_blank'><img height='36' src='https://az743702.vo.msecnd.net/cdn/kofi1.png?v=2' border='0' alt='Buy Me a Coffee at ko-fi.com' /></a>
        <hr/>
        <button onClick={(e) => this.saveRoll(e)}>Save This Roll 💾</button>
        {this.getSaved().length > 0 ? <div className="SavedListBox">
          <select className="SavedList">
            {this.getSaved()}
          </select>
          <a onClick={(e) => this.loadSaved(e)} href="/load">Restore</a>
          <small><a onClick={(e) => this.clearSaved(e)} href="/clear">Clear Saved</a></small>
          <hr/>
        </div> : null}
      </div>
    );
  }
}

export default App;
